import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Gallery from '@browniebroke/gatsby-image-gallery'
import React from 'react'
import Section from '../Section/Section'
import fr from '../../locales/fr'
import { graphql } from 'gatsby'

interface SectionGalleryProps {
  images: GatsbyTypes.GalleryImageFragment[]
}

const SectionGallery: React.FunctionComponent<SectionGalleryProps> = ({ images }) => (
  <Section title={fr.photos} onlyOne>
    <Gallery images={images} />
  </Section>
)

export default SectionGallery

export const GalleryImageFragment = graphql`
  fragment GalleryImage on ImageSharp {
    thumb: fluid(maxWidth: 270, maxHeight: 270) {
      ...GatsbyImageSharpFluid
    }
    full: fluid(maxWidth: 1024) {
      ...GatsbyImageSharpFluid
    }
  }
`
