import Layout from '../components/Layout'
import React from 'react'
import SectionGallery from '../components/SectionGallery'
import Seo from '../components/Seo'
import fr from '../locales/fr'
import { graphql } from 'gatsby'

interface GalleryPageProps {
  data: GatsbyTypes.GalleryPageQuery
}

const GalleryPage: React.FunctionComponent<GalleryPageProps> = ({ data }) => {
  const images: GatsbyTypes.GalleryImageFragment[] = []
  data.allWpPhoto.nodes.forEach(node => {
    if (node.featuredImage?.node?.localFile?.childImageSharp) {
      images.push(node.featuredImage.node.localFile.childImageSharp)
    }
  })
  return (
    <Layout>
      <Seo title={fr.photos} description={fr.photosMetaDesc} />
      <SectionGallery images={images} />
    </Layout>
  )
}

export default GalleryPage

export const pageQuery = graphql`
  query GalleryPage {
    allWpPhoto {
      nodes {
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                ...GalleryImage
              }
            }
          }
        }
      }
    }
  }
`
